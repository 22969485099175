import React, { useState, useContext } from "react"
import useModal from "../hooks/useModal"

export const ContactFormContext = React.createContext()

export const useContactForm = () => useContext(ContactFormContext)

export const ContactFormProvider = ({ children }) => {
  const [formType, setFormType] = useState("")
  const { isOpen, close, setIsOpen } = useModal()

  const open = (type) => {
    if (type) setFormType(type)

    setIsOpen(true)
  }

  const openGeneralForm = () => open("general")
  const openJobForm = () => open("job")
  const openStageForm = () => open("stage")

  return (
    <ContactFormContext.Provider
      value={{
        open,
        close,
        isOpen,
        formType,
        setFormType,
        openGeneralForm,
        openJobForm,
        openStageForm,
      }}
    >
      {children}
    </ContactFormContext.Provider>
  )
}
