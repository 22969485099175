import { useState } from "react"

const useModal = () => {
  const [isOpen, setIsOpen] = useState(false)

  const close = () => setIsOpen(false)

  return { isOpen, close, setIsOpen }
}

export default useModal
